import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import Layout from '../components/Layout';
import Content, {HTMLContent} from '../components/Content';
import Author from "../components/Author";
import '../components/styles/blog_post.sass';
import BlogRollLatest from "../components/BlogRollLatest";
import FooterDivider from "../components/FooterDivider";
import GenericHeader from "../components/header_section/GenericHeader";
import Row from "../components/layouts/Row";
import SideBar from "../components/blog/SideBar";
import DiscussIdeas from "../components/DiscussIdeas";
import styled from "styled-components";
import MobileShareBar from "../components/blog/MobileShareBar";
import {media} from "../components/styles/MediaMixins";

const Container = styled.section`
    margin-top: 3rem;
    min-height: ${props => props.minHeight ? (props.minHeight / 16 + 'rem') : 'unset'};
    display: flex;
    max-width: ${960 / 16}rem;
    padding-right: ${50 / 16}rem;
    padding-left: ${50 / 16}rem;
    margin-right: auto;
    margin-left: auto;
    ${media.mobile`
        padding-right: ${28 / 16}rem;
        padding-left: ${28 / 16}rem;
    `};
    flex-direction: column;
    justify-content: center;
    align-items: ${props => props.justify || 'center'};
    align-content: center;
    ${props => props.forceWrap && 'flex-wrap: wrap'};
    padding-top: ${props => (props.paddingTop || 0) / 16}rem;
    padding-bottom: ${props => (props.paddingBottom || 0) / 16}rem;
`

export const BlogPostTemplate = ({
                                   content,
                                   contentComponent,
                                   description,
                                   tags,
                                   title,
                                   header,
                                 }) => {
  const PostContent = contentComponent || Content;

  return (
    <>
      {header || <GenericHeader title={title} subtitle={description}/>}
      <Container className={'blog-post'}>
        <Row>
          <PostContent content={content}/>
        </Row>
      </Container>
    </>
  )
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({data, location}) => {
  const {markdownRemark: post} = data;

  return (
    <Layout showSubscribe={true}
            article={true}
            meta_image={post.frontmatter.featuredimage.childImageSharp.fluid.src}
            meta_image_width={post.frontmatter.featuredimage.childImageSharp.fluid.presentationWidth}
            meta_image_height={post.frontmatter.featuredimage.childImageSharp.fluid.presentationHeight}
            local_title={post.frontmatter.title}
            hasBackgroundColor={true}
            meta_keyword={post.frontmatter.meta_keyword}
            meta_description={post.frontmatter.meta_description}>
      <SideBar location={location}/>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        header={
          <GenericHeader
            title={post.frontmatter.title}
            subtitle={<Author author={post.frontmatter.author}
                              color={'#FFFFFF'}
                              date={post.frontmatter.date}
                              duration={post.frontmatter.duration}/>}
            backgroundColor={'#FFFFFF00'}
            backgroundImage={post.frontmatter.featuredimage}
          />
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      {post.frontmatter.estimation ? <DiscussIdeas/> : <BlogRollLatest id={post.id}/>}
      <MobileShareBar location={location}/>
      <FooterDivider marginTop={50} marginBottom={70}/>
      <br/>
    </Layout>
  )
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
        duration
        estimation
        meta_description
        meta_keyword
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [768, 1366, 1920]) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        author {
          name
          img {
            childImageSharp {
              fixed(width: 40, height: 40, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`;
