import React from "react";
import {Link} from "gatsby";
import cn from 'classnames'
import './buttons.sass'

export default ({to, className, activeClassName, children}) => {
    return(
        <Link className={cn('cp-button', className)} to={to} activeClassName={activeClassName}>
            {children}
        </Link>
    )
}