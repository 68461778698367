import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import {fluidTypography, media} from "./styles/MediaMixins";

const AuthImg = styled(Img)`
    width: ${40 / 16}rem;
    border-radius: 50%;
`

const PostMeta = styled.div`
    margin-top: ${12 / 16}rem;
    margin-bottom: ${21 / 16}rem;
    display: flex;
  
    div {
        min-width: ${40 / 16}rem;
        display: block;
    }

    span {
        margin-top: auto;
        margin-bottom: auto;
        ${media.touch`
            margin-left: ${5 / 16}rem;
        `};
        margin-left: ${20 / 16}rem;
    }
    a {
        width: auto !important;
    }
`

const Date = styled.span`
    color: ${props => props.color || '#515B6E'}CC;
    font-size: ${18 / 16}rem;
    letter-spacing: 0;
    line-height: ${41 / 16}rem;
    white-space: nowrap;
    ${fluidTypography(14, 18, 320, 768)};
`

const Name = styled.span`
    color: ${props => props.color || '#515B6E'};
    font-size: ${23 / 16}rem;
    letter-spacing: 0;
    line-height: ${41 / 16}rem;
    font-weight: bold;
    white-space: nowrap;
    ${fluidTypography(14, 18, 320, 768)};
`

const Divider = styled.span`
    height: ${17 / 16}rem !important;
    width: ${4 / 16}rem !important;
    background-color: #D8D8D899;
    ${media.touch`
        width: ${3 / 16}rem !important;
    `};
`

const Duration = styled.span`
    color: ${props => props.color || '#515B6E'}CC;
    font-size: ${18 / 16}rem;
    letter-spacing: 0;
    line-height: ${41 / 16}rem;
    white-space: nowrap;
    ${fluidTypography(14, 18, 320, 768)};
`

export default ({author, color, date, tags, duration}) => {
    return (
        <PostMeta>
            <AuthImg fixed={author.img.childImageSharp.fixed}/>
            <Name color={color}>{author.name}</Name>
            <Divider/>
            <Date color={color}>{date.split(',')[0]}</Date>
            <Divider/>
            <Duration color={color}>{duration}</Duration>
            {tags &&
            <>
                <Divider/>
                <span style={{color: '#38EF7D'}}>{tags.join(' ')}</span>
            </>}
        </PostMeta>
    )
}