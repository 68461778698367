import React from 'react'
import {graphql, Link, useStaticQuery} from 'gatsby'
import Title from "./title/Title";
import Container from "./layouts/Container";
import styled from "styled-components";
import {media} from "./styles/MediaMixins";
import Row from "./layouts/Row";
import OrderedRowItem from "./layouts/OrderedRowItem";
import BlogRoll from "./BlogRoll";

const SeeAll = styled(Link)`
    color: #20BB87;
    ${media.desktop`font-size: ${19 / 16}rem`};
    ${media.desktop`line-height: ${19 / 16}rem`};
    ${media.touch`font-size: ${14 / 16}rem`};
    ${media.touch`line-height: ${14 / 16}rem`};
    font-weight: bold;
`

export default ({id}) => {
    const data = useStaticQuery(graphql`
        query BlogRollLatestQuery {
            allMarkdownRemark(
                limit: 4
                sort: { order: DESC, fields: [frontmatter___date] }
                filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
            ) {
                edges {
                    node {
                        id
                        fields {
                            slug
                        }
                        frontmatter {
                            title
                            templateKey
                            description
                            tags
                            date(formatString: "MMMM DD, YYYY")
                            duration
                            author {
                                name
                                img {
                                    childImageSharp {
                                        fixed(width: 40, height: 40) {
                                            ...GatsbyImageSharpFixed_withWebp
                                        }
                                    }
                                }
                            }
                            featuredimage {
                                childImageSharp {
                                    fluid(maxWidth: 504, maxHeight: 354, quality: 100) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `);
    const posts = data.allMarkdownRemark.edges.filter(edge => edge.node.id !== id).slice(0, 3);
    return (
        <>
            <Container breakpoint={'desktop'} paddingTop={56} paddingBottom={16}>
                <Row alignContent={"flex-end"} breakpoint={'desktop'}>
                    <OrderedRowItem breakpoint={'desktop'}>
                        <Title>Recent Posts</Title>
                    </OrderedRowItem>

                    <OrderedRowItem breakpoint={'desktop'} textAlign={'right'}>
                        <SeeAll to={'/blog/'}>
                            SEE ALL &#8594;
                        </SeeAll>
                    </OrderedRowItem>
                </Row>
            </Container>
            <BlogRoll posts={posts}/>
        </>
    )
}
