import React from 'react';
import PropTypes from 'prop-types';
import Author from "./Author";
import Paragraph from "./paragraph/Paragraph";
import CPLink from "./button/CPLink";
import Container from "./layouts/Container";
import styled from "styled-components";
import PreviewCompatibleBlogTitleImage from "./blog/PreviewCompatibleBlogTitleImage";
import {fluidSizing, fluidTypography, media} from "./styles/MediaMixins";

const BlogPicture = styled(PreviewCompatibleBlogTitleImage)`
    border: 1px solid #979797;
    height: 354px;
    ${media.mobile`
        max-height: 60vw !important;
        min-width: 264px !important;
    `};
`

const BlogPostTitle = styled.div`
    color: #515B6E;
    font-weight: bold;
    letter-spacing: 0;
    ${fluidTypography(18, 28)};
    ${fluidSizing('line-height', 18, 41)};
    vertical-align: middle;
    ${media.fullhd`
        min-height: ${82 / 16}rem;
        margin-top: ${11 / 16}rem;
    `};
    ${media.widescreen_only`
        min-height: ${72 / 16}rem;
        margin-top: ${11 / 16}rem;
    `};
    ${media.desktop_only`
        line-height: ${64 / 16}rem;
        margin-top: ${11 / 16}rem;
    `};
    ${media.touch`
        margin-bottom: ${5 / 16}rem;
        margin-top: ${11 / 16}rem;
    `};
`

const ReadMore = styled(CPLink)`
    max-width: ${169 / 16}rem;
    letter-spacing: 0;
    font-size: ${12.48 / 16}rem;
    padding: ${14 / 16}rem ${45 / 16}rem;
    text-align: center;
    width: auto !important;
    white-space: nowrap;
    margin-top: ${20 / 16}rem;
`

const BlogTags = styled.div`
    position: absolute;
    bottom: 4%;
    left: 3%;
    color: #FFFFFFCC;
    font-size: ${14.4 / 16}rem;
    line-height:  ${25.6 / 16}rem;
    font-weight: 700;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
`

const BlogRowItem = styled.div`
    display: flex;
    flex-direction: column;
    ${media.fullhd`
        width: ${504 * 100 / 1570}%;
        min-width: ${410 / 16}rem;
    `};
    ${media.widescreen_only`
        width: 45%;
        min-width: ${520 / 16}rem;
    `};
    ${media.desktop_only`
        width: ${720 / 16}rem;
    `};
    ${media.touch`
        width: 100%;
        margin-top: 2rem;
    `};
    margin-bottom: ${30 / 16}rem;
`

const BlogRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    ${media.desktop_only`
       justify-content: center;
    `};
    flex-wrap: wrap;
    align-items: stretch;
    align-content: stretch;
    max-width: 1570px;
`

const BlogRollDescription = styled(Paragraph)`
    ${fluidTypography(12, 14)};
    ${fluidSizing('line-height', 16, 23)};
`

const BlogContainer = styled(Container)`
    ${media.mobile`
        padding-right: ${28 / 16}rem;
        padding-left: ${28 / 16}rem;
    `};
`

function shorten(str, maxLen, separator = ' ') {
  if (str.length <= maxLen) return str;
  return str.substr(0, str.lastIndexOf(separator, maxLen));
}

function BlogRoll(props) {
  const {posts} = props;

  return (
    <BlogContainer>
      <BlogRow>
        {posts &&
        [...posts.map(({node: post}, index) => (
          <BlogRowItem key={index} maxWidth={504}>
            <BlogPicture
              imageInfo={{
                image: post.frontmatter.featuredimage,
                alt: `image thumbnail for post ${post.frontmatter.title}`,
              }}
            >
              <BlogTags>{post.frontmatter.tags.join('\u00A0\u00A0')}</BlogTags>
            </BlogPicture>
            <BlogPostTitle title={post.frontmatter.title}>
              {post.frontmatter.title.length > 60 ? shorten(post.frontmatter.title, 58) + '...' : post.frontmatter.title}
            </BlogPostTitle>
            <Author author={post.frontmatter.author} date={post.frontmatter.date}
                    duration={post.frontmatter.duration}/>
            <BlogRollDescription marginBottom={10} style={{flexGrow: '1000'}}>
              {post.frontmatter.description}
            </BlogRollDescription>
            <ReadMore to={post.fields.slug} className={'green'}>READ MORE</ReadMore>
          </BlogRowItem>
        )), <BlogRowItem key={posts.length + 1}/>]}
      </BlogRow>
    </BlogContainer>
  )
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.array,
  }),
};

export default BlogRoll;