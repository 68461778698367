import React, {useRef} from 'react'
import styled from "styled-components";
import {media} from "../styles/MediaMixins";
import fb from '../../img/blog/facebook-logo.svg'
import tw from '../../img/blog/twitter-logo.svg'
import li from '../../img/blog/linkedin-logo.svg'
import sh from '../../img/blog/copy-link-logo.svg'
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";
import ShowToast from "../toast/ShowToast";

const SingleShare = styled.div`
    display: flex;
    margin: ${5 / 16}rem auto;
    width: ${40 / 16}rem;
    height: ${40 / 16}rem;
    button {
        margin: auto;
        width: 90%;
        height: 90%;
    }
`

const Container = styled.div`
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    ${media.touch`position: fixed`};
    ${media.desktop`display: none`};
    background-color: #D8D8D8;
    z-index: 200;
`

const CopyButton = styled.button`
    border: none;
    cursor: pointer;
    background: transparent;
`

export default ({location}) => {
    const clipboard = useRef();
    const handleCopy = () => {
        if (document) {
            const selected =
                document.getSelection().rangeCount > 0
                    ? document.getSelection().getRangeAt(0)
                    : false;
            clipboard.current.select();
            document.execCommand('copy');
            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }
            ShowToast('success', `Link has been copied to clipboard`);
        }
    };
    return (
        <Container>
            <input style={{position: 'absolute', left: '-9999px'}} ref={clipboard} defaultValue ={location.href} readOnly={true}/>
            <SingleShare border={true}>
                <FacebookShareButton url={location.href}>
                    <img src={fb} alt={'Facebook icon'}/>
                </FacebookShareButton>
            </SingleShare>
            <SingleShare border={true}>
                <TwitterShareButton url={location.href}>
                    <img src={tw} alt={'Twitter icon'}/>
                </TwitterShareButton>
            </SingleShare>
            <SingleShare border={true}>
                <LinkedinShareButton url={location.href}>
                    <img src={li} alt={'LinkedIn icon'}/>
                </LinkedinShareButton>
            </SingleShare>
            <SingleShare>
                <CopyButton onClick={handleCopy}>
                    <img src={sh} alt={'Copy Link icon'}/>
                </CopyButton>
            </SingleShare>
        </Container>
    )
}