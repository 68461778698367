import React from 'react'
import PropTypes from 'prop-types'
import BackgroundImage from 'gatsby-background-image'

const PreviewCompatibleBlogTitleImage = ({imageInfo, className, children}) => {
    const {alt = '', childImageSharp, image} = imageInfo;

    if (!!image && !!image.childImageSharp) {
        return (
            <BackgroundImage className={className}
                             fluid={[`linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%)`, image.childImageSharp.fluid]}
                             alt={alt}>
                {children}
            </BackgroundImage>
        )
    }

    if (!!childImageSharp) {
        return <BackgroundImage className={className}
                                fluid={[`linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%)`, childImageSharp.fluid]}
                                alt={alt}>
            {children}
        </BackgroundImage>
    }

    if (!!image && typeof image === 'string')
        return <div className={className} style={{background: `url: ${image}`}}>
            {children}
        </div>;

    return null
};

PreviewCompatibleBlogTitleImage.propTypes = {
    imageInfo: PropTypes.shape({
        alt: PropTypes.string,
        childImageSharp: PropTypes.object,
        image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
        style: PropTypes.object,
    }).isRequired,
};

export default PreviewCompatibleBlogTitleImage
